import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';
import Barcode from 'react-barcode';


import ReactLoading from 'react-loading';
import config from "../config"
const Print_label = () => {
  const location = useLocation()
  const shipment_id = useParams()
  // console.log("shipment_id",shipment_id.shipment_id)

  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }

  const [state, setState] = React.useState({ shipment_list: [], total: 0, company_info: {}, customer_label_setting: {} })
  // console.log("state",state)
  const [dimensionState, setdimensionState] = React.useState({ companyDetail: [] })
  const [isloading, setisloading] = React.useState(true)

  const [shipmentsList, setShipmentList] = React.useState([])

  React.useEffect(() => {

    shipment_list()
    dimension_detail()

  }, [])

  React.useEffect(() => {
    // console.log("ss", state.customer_label_setting.label_size_print);
    // if (state.customer_label_setting.label_size_print == "8x11") {
    //   let arr1 = [];
    //   for (let i = 0; i < state.shipment_list.length; i += 4)
    //     arr1.push(state.shipment_list.slice(i, i + 4));
    //   console.log(arr1);
    //   setShipmentList(arr1)

    // } else {
    //   console.log("else");
    //   setShipmentList(state.shipment_list)
    // }


  }, [])

  const shipment_list = () => {
    let dataToSend = {
      customer_id: userData.customer_id, shipment_id: shipment_id.shipment_id
    };

    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/shipment/print_label_shipments';

    // // console.log("headers => ", config.headers);


    axios.post(url, dataToSend, { headers: config.headers })
      .then((res) => {
        // console.log("responseJson => ", res);
        setState({ shipment_list: res.data.output, total: res.data.total, company_info: res.data.company_info, customer_label_setting: res.data.customer_label_setting })
        let limit = 4;

        if (res.data.customer_label_setting.label_size_print == "4x6") {
          limit = 1;
        }
        let arr1 = [];
        for (let i = 0; i < res.data.output.length; i += limit)
          arr1.push(res.data.output.slice(i, i + limit));
        console.log(arr1);
        setShipmentList(arr1)

        //  } else {
        //  console.log("else");
        //  setShipmentList(res.data.output)
        // }

        setisloading(false)
        setTimeout(() => {
          window.print();
        }, 1000)




      })
      .catch((error) => {
        //Hide Loader
        //   setLoadingstate({...loadingstate,loading:false})
        // console.log(error);
      });

  }
  const SenderAddress = () => {
    if (state.shipment_list[0].items.length > 0) {
      return state.shipment_list[0].items.reduce((previousValue, currentValue) => {
        return previousValue.item_weight + currentValue.item_weight
      })
    } else {
      return 0
    }
  }


  const dimension_detail = () => {
    let full_api = config.apiUrl + `/user/get_company_info_api`;
    let sendData = {};

    axios.post(full_api, sendData, { headers: config.headers }).then(res => {
      setdimensionState({ ...dimensionState, companyDetail: res.data.companyDetail });
      // console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
      //toast.error("Some thing went wrong")
      // console.log("----error:   ", e);
    })
  }
  const FunctionComponent = (shipment_id) => {
    // console.log("shipment_id == " , shipment_id)
    return <Barcode value={shipment_id.value} />;
  };
  const FunctionComponent1 = () => {
    return <Barcode value={state.shipment_list[0].carrier_tracking_number} />;
  };
  const tableStyle = {
    fontFamily: 'sans-serif',
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: '13px',
    verticalAlign: 'top',
  };

  const trStyle = {
    textAlign: 'left',
    padding: '18px',
  };

  const thTdStyle = {
    padding: '1px 10px',
    verticalAlign: 'top',
  };

  const evenRowStyle = {
    backgroundColor: '#fff',
  };

  const borderStyle = {
    border: '2px solid #000',
  };

  const centerStyle = {
    textAlign: 'center',
  };

  const monospaceStyle = {
    font: '20px monospace',
  };

  const a4PaperHeightPx = Math.round((297 * 96) / 25.4);
  return (

    <div>
      {isloading == false ?
        shipmentsList.map((ships, index) => (
          <>


            <div className="pagebreak" key={index}>
              <section class="labling_929393">
                {(state.customer_label_setting.label_size_print == "8x11") ?
              
                  <div className='row label-8x11-layout'>
                    {ships.map((sub, index) =>
                    
                   
                    <div className="col-12 col-md-5">
                    <section class="mb-3 sticky-top">
                           <div class="card border rounded-0 lable_layout_1">
                               <div class="card-body">
                                   <div class="row">
                                       <div class="col-9 col-md-9">
                                           <h2>To:</h2>
                                           <h3>{sub.receiver[0].name}</h3>
                                           <h3>{sub.receiver[0].address}, {sub.receiver[0].city}, {sub.receiver[0].state}, {sub.receiver[0].country}</h3>
                                           {(state.customer_label_setting.hide_customer_mobile_number) ? <h3>Mobile No: {sub.receiver[0].mobile}</h3> : ""}
                                           <h3>{sub.receiver[0].pincode}</h3>
                                       </div>
                                       {/* {(state.customer_label_setting.show_logo_on_label) ?
                                           <div class="col-3 col-md-3">
                                               <img src={(state.customer_label_setting.customer_logo && !state.customer_label_setting.use_channel_logo) ? "https://cdn.shipdartexpress.com/images/" + state.customer_label_setting.customer_logo : `https://cdn.shipdartexpress.com/images/${sub.carrier_image}`} class="img-fluid logo"  height="10px"/>
 
                                           </div>
                                           : ""
                                       } */}

                                                   {(state.customer_label_setting.show_logo_on_label && state.customer_label_setting.customer_logo ) ?
                                                    <div class="col-3 col-md-3">
                                                        <img src={ "https://cdn.shipdartexpress.com/images/" + state.customer_label_setting.customer_logo } class="img-fluid logo" height="10px" />

                                                    </div>
                                                    
                                                    
                                                   :""
                                                }
                                   </div>
                                   <hr />
                                   <div class="row mb-2">
                                       <div class="col-6 ">
                                           <h3>Order Date: <strong>{(sub.order_date)}</strong></h3>
                                           <h3>Invoice No: <strong>{sub.order_number}</strong></h3>
                                       </div>
                                       <div class="col-6 text-end svg-container">
                                     {dimensionState.companyDetail[0].hide_carrier?"":  <img src= { `https://cdn.shipdartexpress.com/images/${sub.carrier_image}`} class="img-fluid logo"  style={{height:"60px"}}/>}
                                       {/* <Barcode value={sub.carrier_tracking_number} height={40} /> */}

                                           {/* <h6>{sub.order_number}</h6> */}
                                       </div>
                                   </div>
                                   <div className='row'>
                                    <div className='col-12 text-center'>
                                         <Barcode value={sub.carrier_tracking_number} height={60} width={3} displayValue="false" /> 
                                         <h6 style={{fontSize:"25px"}}>{sub.carrier_tracking_number}</h6>
                                    </div>
                                   </div>
                                   <hr />
                                   <div class="row mb-2">
                                       <div class="col-6 col-md text-center">
                                           <h5>{sub.payment_mode}</h5>
                                           <h5>{sub.payment_mode=="prepaid"?"":'₹'+sub.cod_amount}</h5>
                                           <small>WEIGHT: {sub.total_weight}KG</small>
                                       </div>
                                       <div class="col-6 col-md text-center">
                                           <h6>{sub.service_provider}</h6>
                                           <h6>{sub.carrier_tracking_number?sub.carrier_tracking_number:""}</h6>
                                           <small>Dimensions (cm): {sub.height}X {sub.width}X {sub.length}</small>
                                       </div>
                                   </div>
 
                                   <div class="table-responsive mb-2">
                                       <table class="table table-bordered border">
                                           <thead>
                                               <tr>
                                                   {state.customer_label_setting.hide_product ? <th>Item Name</th> : ""}
                                                   {state.customer_label_setting.hide_qty ? <th>Qty.</th> : ""}
                                                   {state.customer_label_setting.hide_total_amount ? <th>Amount</th> : ""}
                                               </tr>
                                           </thead>
                                           <tbody>
                                           {sub.items.slice(0, (state.customer_label_setting.show_number_of_line_items !== undefined && state.customer_label_setting.show_number_of_line_items !== "" ? state.customer_label_setting.show_number_of_line_items : sub.items.length)).map((item)=>(

<tr>
{state.customer_label_setting.hide_product ? <td>{state.customer_label_setting.trim_product_name_upto!=undefined && state.customer_label_setting.trim_product_name_upto!=""?(item.item_name.substring(0,state.customer_label_setting.trim_product_name_upto)):item.item_name}</td>:""}
{state.customer_label_setting.hide_qty ? <td>{item.item_quantity}</td> : ""}
{state.customer_label_setting.hide_total_amount ? <td>&#8377;{item.item_value}</td> : ""}
</tr>
))}
                                                      
 
                                              
 
                                           </tbody>
                                       </table>
                                   </div>
                                   {!state.customer_label_setting.hide_pickup_address && !state.customer_label_setting.hide_pickup_mobile_number?"":
                                   <div class="mb-2">
                                       <h2>Pickup Address:</h2>
                                       {state.customer_label_setting.hide_pickup_address ? <h4>{sub.warehouse_id?sub.warehouse_id:sub.sender[0].name}</h4> : ""}
                                       {state.customer_label_setting.hide_pickup_address ? <h3>{sub.sender[0].address} {sub.sender[0].city}, {sub.sender[0].state}, {sub.sender[0].country} - {sub.sender[0].pincode}</h3> : ""}
                                       <h3>{state.customer_label_setting.hide_pickup_mobile_number ? `Mobile No: ${sub.sender[0].mobile} ,` : ""} </h3>
                                   </div>
}
                                   {!state.customer_label_setting.hide_rto_address && !state.customer_label_setting.hide_rto_mobile_number?"":
                                   <div class="mb-2">
                                       <h2>Return Address:</h2>
                                       {state.customer_label_setting.hide_rto_address ? <h4>{sub.warehouse_id?sub.warehouse_id:sub.return[0].name}</h4> : ""}
                                       {state.customer_label_setting.hide_rto_address ? <h3>{sub.return[0].address} {sub.return[0].city}, {sub.return[0].state}, {sub.return[0].country} - {sub.return[0].pincode}</h3> : ""}
                                       <h3>{state.customer_label_setting.hide_rto_mobile_number ? `Mobile No: ${sub.return[0].mobile} ,`  : ""}  </h3>
                                   </div>
}
 
{!state.customer_label_setting.show_support_email_mobile ?"":
                              <div class="mb-2">
                                  <h2>For any query please contact:</h2>
                                  <h3>{state.customer_label_setting.show_support_email_mobile ? `Mobile: ${state.customer_label_setting.support_mobile ? state.customer_label_setting.support_mobile : ""}, Email: ${state.customer_label_setting.support_email ? state.customer_label_setting.support_email : ""}` : ""}</h3>
                              </div>}
 
                                   <hr />
                                   {/* <small>This is computer generated document, hence does not required signature.</small>
                                   <div><strong>Note:</strong> <small>All disputes are subject to Delhi jurisdiction. Goods once sold will only be taken back or exchanged as per the store's exchange/return policy</small></div> */}
 
 <div className='custom-row'>
  <div>
    <small>Powered By Shipdartexpress</small>
  </div>
  <div>
    <img src='../../../img/labels_icon_kw.png' width="70px" alt="Logo" />
  </div>
</div>
 
                               </div>
                           </div>
 
                       </section>
                    </div>
                   
                
                    )}
                  
                  </div>
                 

                  : (state.customer_label_setting.label_size_print == "4x6") ?
                    <div className='row label4x6'>
                      {ships.map((sub, index) =>
                       <div class="col-12 col-md-5">
                        <section class="mb-3 sticky-top" >
                           <div class="card border rounded-0 lable_layout_1" >
                               <div class="card-body " >
                                   <div class="row">
                                       <div class="col-9 col-md-9">
                                           <h2 class="print-font">To:</h2>
                                           <h3 class="print-font">{sub.receiver[0].name}</h3>
                                           <h3 class="print-font">{sub.receiver[0].address}, {sub.receiver[0].city}, {sub.receiver[0].state}, {sub.receiver[0].country}</h3>
                                           {(state.customer_label_setting.hide_customer_mobile_number) ? <h3 class="print-font">Mobile No: {sub.receiver[0].mobile}</h3> : ""}
                                           <h3 class="print-font">{sub.receiver[0].pincode}</h3>
                                       </div>
                                       {(state.customer_label_setting.show_logo_on_label && state.customer_label_setting.customer_logo) ?
                                                    <div class="col-3 col-md-3">
                                                        <img src={ "https://cdn.shipdartexpress.com/images/" + state.customer_label_setting.customer_logo } class="img-fluid logo" height="10px" />

                                                    </div>
                                          :""
                                                }
                                   </div>
                                   <hr />
                                   <div class="row mb-3">
                                       <div class="col-6 ">
                                           <h3 class="print-font">Order Date: <strong>{(sub.order_date)}</strong></h3>
                                           <h3 class="print-font">Invoice No: <strong>{sub.order_number}</strong></h3>
                                       </div>
                                       <div class="col-6 text-end svg-container">
                                       {dimensionState.companyDetail[0].hide_carrier?"":  <img src= { `https://cdn.shipdartexpress.com/images/${sub.carrier_image}`} class="img-fluid logo"  style={{height:"60px"}}/>}
                                       {/* <Barcode value={sub.carrier_tracking_number} height={40} /> */}

                                           {/* <h6>{sub.order_number}</h6> */}
                                       </div>
                                   </div>
                                   <div className='row'>
                                    <div className='col-12 text-center'>
                                         <Barcode value={sub.carrier_tracking_number} height={80} width={3} displayValue="false" /> 
                                         <h6 style={{fontSize:"25px"}}>{sub.carrier_tracking_number}</h6>
                                    </div>
                                   </div>
                                   <hr />
                                   <div class="row mb-5">
                                       <div class="col-6 col-md text-center">
                                           <h5 class="print-font " > {sub.payment_mode}</h5>
                                           <h5 class="print-font " >{sub.payment_mode=="prepaid"?"":'₹'+sub.cod_amount}</h5>
                                           <small class="print-font">WEIGHT: {sub.total_weight}KG</small>
                                       </div>
                                       <div class="col-6 col-md text-center">
                                           <h6 class="print-font" >{sub.service_provider}</h6>
                                           <h6 class="print-font" > {sub.carrier_tracking_number?sub.carrier_tracking_number:""}</h6>
                                           <small class="print-font">Dimensions (cm): {sub.height}X {sub.width}X {sub.length}</small>
                                       </div>
                                   </div>
 
                                   <div class="table-responsive  mb-4 ">
                                       <table class="table table-bordered border print-table">
                                           <thead>
                                               <tr>
                                                   {state.customer_label_setting.hide_product ? <th >Item Name</th> : ""}
                                                   {state.customer_label_setting.hide_qty ? <th>Qty.</th> : ""}
                                                   {state.customer_label_setting.hide_total_amount ? <th >Amount</th> : ""}
                                               </tr>
                                           </thead>
                                           <tbody>
                                           {sub.items.slice(0, (state.customer_label_setting.show_number_of_line_items !== undefined && state.customer_label_setting.show_number_of_line_items !== "" ? state.customer_label_setting.show_number_of_line_items : sub.items.length)).map((item)=>(

<tr>
{state.customer_label_setting.hide_product ? <td>{state.customer_label_setting.trim_product_name_upto!=undefined && state.customer_label_setting.trim_product_name_upto!=""?(item.item_name.substring(0,state.customer_label_setting.trim_product_name_upto)):item.item_name}</td>:""}
{state.customer_label_setting.hide_qty ? <td>{item.item_quantity}</td> : ""}
{state.customer_label_setting.hide_total_amount ? <td>&#8377;{item.item_value}</td> : ""}
</tr>
))}
                                                      
 
                                              
 
                                           </tbody>
                                       </table>
                                   </div>
                                   {!state.customer_label_setting.hide_pickup_address && !state.customer_label_setting.hide_pickup_mobile_number?"":
                                   <div class="mb-4">
                                       <h2 class="print-font">Pickup Address:</h2>
                                       {state.customer_label_setting.hide_pickup_address ? <h4 class="print-font">{sub.warehouse_id?sub.warehouse_id:sub.sender[0].name}</h4> : ""}
                                       {state.customer_label_setting.hide_pickup_address ? <h3 class="print-font">{sub.sender[0].address} {sub.sender[0].city}, {sub.sender[0].state}, {sub.sender[0].country} - {sub.sender[0].pincode}</h3> : ""}
                                       <h3 class="print-font">{state.customer_label_setting.hide_pickup_mobile_number ? `Mobile No: ${sub.sender[0].mobile} ,` : ""} </h3>
                                   </div>
}
                                   {!state.customer_label_setting.hide_rto_address && !state.customer_label_setting.hide_rto_mobile_number?"":
                                   <div class="mb-4">
                                       <h2 class="print-font">Return Address:</h2>
                                       {state.customer_label_setting.hide_rto_address ? <h4 class="print-font">{sub.warehouse_id?sub.warehouse_id:sub.return[0].name}</h4> : ""}
                                       {state.customer_label_setting.hide_rto_address ? <h3 class="print-font">{sub.return[0].address} {sub.return[0].city}, {sub.return[0].state}, {sub.return[0].country} - {sub.return[0].pincode}</h3> : ""}
                                       <h3 class="print-font">{state.customer_label_setting.hide_rto_mobile_number ? `Mobile No: ${sub.return[0].mobile} ,`  : ""}  </h3>
                                   </div>
}
 
{!state.customer_label_setting.show_support_email_mobile ?"":
                              <div class="mb-3">
                                  <h2 class="print-font">For any query please contact:</h2>
                                  <h3 class="print-font">{state.customer_label_setting.show_support_email_mobile ? `Mobile: ${state.customer_label_setting.support_mobile ? state.customer_label_setting.support_mobile : ""}, Email: ${state.customer_label_setting.support_email ? state.customer_label_setting.support_email : ""}` : ""}</h3>
                              </div>}
 
                                   <hr />
                                   {/* <small class="print-font print-spacing">This is computer generated document, hence does not required signature.</small>
                                   <div class="print-font print-spacing"><strong>Note:</strong> <small>All disputes are subject to Delhi jurisdiction. Goods once sold will only be taken back or exchanged as per the store's exchange/return policy</small></div> */}
                                    <div className='custom-row'>
  <div>
    <small>Powered By Shipdartexpress</small>
  </div>
  <div>
    <img src='../../../img/labels_icon_kw.png' width="70px" alt="Logo" />
  </div>
</div>
 
 
 
                               </div>
                           </div>
 
                       </section>
 
                   </div>
                      )}
                    </div>
                    :
                    Object.keys(state.customer_label_setting).length === 0 ?
                
                    <div className='row label-8x11-layout'>
                    {ships.map((sub, index) =>
                    
                   
                    <div className="col-12 col-md-5">
                    <section class="mb-3 sticky-top" >
                           <div class="card border rounded-0 lable_layout_1" >
                               <div class="card-body" >
                                   <div class="row">
                                       <div class="col-9 col-md-9">
                                           <h2>To:</h2>
                                           <h3>{sub.receiver[0].name}</h3>
                                           <h3>{sub.receiver[0].address}, {sub.receiver[0].city}, {sub.receiver[0].state}, {sub.receiver[0].country}</h3>
                                            <h3>Mobile No: {sub.receiver[0].mobile}</h3> 
                                           <h3>{sub.receiver[0].pincode}</h3>
                                       </div>
                                      
                                           <div class="col-3 col-md-3">
                                               {/* <img src={ `https://cdn.shipdartexpress.com/images/${sub.carrier_image}`} class="img-fluid logo"  height="10px"/> */}
 
                                           </div>
                                          
                                   </div>
                                   <hr />
                                   <div class="row mb-2">
                                       <div class="col-6">
                                           <h3>Order Date: <strong>{(sub.order_date)}</strong></h3>
                                           <h3>Invoice No: <strong>{sub.order_number}</strong></h3>
                                       </div>
                                       <div class="col-6 text-end svg-container">
                                       {dimensionState.companyDetail[0].hide_carrier?"":    <img src= { `https://cdn.shipdartexpress.com/images/${sub.carrier_image}`} class="img-fluid logo"  style={{height:"60px"}}/>}
                                       {/* <Barcode value={sub.carrier_tracking_number} height={40} /> */}

                                           {/* <h6>{sub.order_number}</h6> */}
                                       </div>
                                   </div>
                                   <div className='row'>
                                    <div className='col-12 text-center'>
                                         <Barcode value={sub.carrier_tracking_number} height={60} width={3} displayValue="false" /> 
                                         <h6 style={{fontSize:"25px"}}>{sub.carrier_tracking_number}</h6>
                                    </div>
                                   </div>
                                   <hr />
                                   <div class="row mb-2">
                                       <div class="col-6 col-md text-center">
                                           <h5>{sub.payment_mode}</h5>
                                           <h5>{sub.payment_mode=="prepaid"?"":'₹'+sub.cod_amount}</h5>
                                           <small>WEIGHT: {sub.total_weight}KG</small>
                                       </div>
                                       <div class="col-6 col-md text-center">
                                           <h6>{sub.service_provider}</h6>
                                           <h6>{sub.carrier_tracking_number?sub.carrier_tracking_number:""}</h6>
                                           <small>Dimensions (cm): {sub.height}X {sub.width}X {sub.length}</small>
                                       </div>
                                   </div>
 
                                   <div class="table-responsive mb-2">
                                       <table class="table table-bordered border">
                                           <thead>
                                               <tr>
                                                  <th>Item Name</th> 
                                                 <th>Qty.</th> 
                                                 <th>Amount</th> 
                                               </tr>
                                           </thead>
                                           <tbody>
                                           {sub.items.map((item)=>(

                                              <tr>
                                            <td>{item.item_name}</td>
                                               <td>{item.item_quantity}</td> 
                                              <td>&#8377;{item.item_value}</td> 
                                              </tr>
                                              ))}
                                                      
 
                                              
 
                                           </tbody>
                                       </table>
                                   </div>
                                 
                                   <div class="mb-2">
                                       <h2>Pickup Address:</h2>
                                     <h4>{sub.warehouse_id?sub.warehouse_id:sub.sender[0].name}</h4> 
                                       <h3>{sub.sender[0].address} {sub.sender[0].city}, {sub.sender[0].state}, {sub.sender[0].country} - {sub.sender[0].pincode}</h3> 
                                       <h3>Mobile No: {sub.sender[0].mobile}</h3>
                                   </div>

                                 
                                   <div class="mb-2">
                                       <h2>Return Address:</h2>
                                      <h4>{sub.warehouse_id?sub.warehouse_id:sub.return[0].name}</h4> 
                                       <h3>{sub.return[0].address} {sub.return[0].city}, {sub.return[0].state}, {sub.return[0].country} - {sub.return[0].pincode}</h3> 
                                       <h3>Mobile No: {sub.return[0].mobile}  </h3>
                                   </div>

 
                                   {/* <div class="mb-3">
                                       <h2>For any query please contact:</h2>
                                       <h3>{state.customer_label_setting.show_support_email_mobile ? `Mobile: ${state.customer_label_setting.support_mobile ? state.customer_label_setting.support_mobile : ""}, Email: ${state.customer_label_setting.support_email ? state.customer_label_setting.support_email : ""}` : ""}</h3>
                                   </div> */}
 
                                   <hr />
                                   {/* <small>This is computer generated document, hence does not required signature.</small>
                                   <div><strong>Note:</strong> <small>All disputes are subject to Delhi jurisdiction. Goods once sold will only be taken back or exchanged as per the store's exchange/return policy</small></div> */}
                                    <div className='custom-row'>
  <div>
    <small>Powered By Shipdartexpress</small>
  </div>
  <div>
    <img src='../../../img/labels_icon_kw.png' width="70px" alt="Logo" />
  </div>
</div>
 
 
 
                               </div>
                           </div>
 
                       </section>
                    </div>
                   
                
                    )}
                  
                  </div>  
               :
               <div className='row label-8x11-layout'>
               {ships.map((sub, index) =>
               
              
               <div className="col-12 col-md-5">
               <section class="mb-3 sticky-top">
                      <div class="card border rounded-0 lable_layout_1">
                          <div class="card-body">
                              <div class="row">
                                  <div class="col-9 col-md-9">
                                      <h2>To:</h2>
                                      <h3>{sub.receiver[0].name}</h3>
                                      <h3>{sub.receiver[0].address}, {sub.receiver[0].city}, {sub.receiver[0].state}, {sub.receiver[0].country}</h3>
                                      {(state.customer_label_setting.hide_customer_mobile_number) ? <h3>Mobile No: {sub.receiver[0].mobile}</h3> : ""}
                                      <h3>{sub.receiver[0].pincode}</h3>
                                  </div>
                                  {(state.customer_label_setting.show_logo_on_label && state.customer_label_setting.customer_logo ) ?
                                                    <div class="col-3 col-md-3">
                                                        <img src={ "https://cdn.shipdartexpress.com/images/" + state.customer_label_setting.customer_logo } class="img-fluid logo" height="10px" />

                                                    </div>
                                                    
                                                    
                                                 :""
                                                }
                              </div>
                              <hr />
                              <div class="row mb-2">
                                  <div class="col-6 ">
                                      <h3>Order Date: <strong>{(sub.order_date)}</strong></h3>
                                      <h3>Invoice No: <strong>{sub.order_number}</strong></h3>
                                  </div>
                                  <div class="col-6 text-end svg-container">
                                  {dimensionState.companyDetail[0].hide_carrier?"":   <img src= { `https://cdn.shipdartexpress.com/images/${sub.carrier_image}`} class="img-fluid logo"  style={{height:"60px"}}/>}
                                       {/* <Barcode value={sub.carrier_tracking_number} height={40} /> */}

                                           {/* <h6>{sub.order_number}</h6> */}
                                       </div>
                                   </div>
                                   <div className='row'>
                                    <div className='col-12 text-center'>
                                         <Barcode value={sub.carrier_tracking_number} height={60} width={3} displayValue="false" /> 
                                         <h6 style={{fontSize:"25px"}}>{sub.carrier_tracking_number}</h6>
                                    </div>
                                   </div>
                              <hr />
                              <div class="row mb-2">
                                  <div class="col-6 col-md text-center">
                                      <h5>{sub.payment_mode}</h5>
                                      <h5>{sub.payment_mode=="prepaid"?"":'₹'+sub.cod_amount}</h5>
                                      <small>WEIGHT: {sub.total_weight}KG</small>
                                  </div>
                                  <div class="col-6 col-md text-center">
                                      <h6>{sub.service_provider}</h6>
                                      <h6>{sub.carrier_tracking_number?sub.carrier_tracking_number:""}</h6>
                                      <small>Dimensions (cm): {sub.height}X {sub.width}X {sub.length}</small>
                                  </div>
                              </div>

                              <div class="table-responsive mb-2">
                                  <table class="table table-bordered border">
                                      <thead>
                                          <tr>
                                              {state.customer_label_setting.hide_product ? <th>Item Name</th> : ""}
                                              {state.customer_label_setting.hide_qty ? <th>Qty.</th> : ""}
                                              {state.customer_label_setting.hide_total_amount ? <th>Amount</th> : ""}
                                          </tr>
                                      </thead>
                                      <tbody>
                                      {sub.items.slice(0, (state.customer_label_setting.show_number_of_line_items !== undefined && state.customer_label_setting.show_number_of_line_items !== "" ? state.customer_label_setting.show_number_of_line_items : sub.items.length)).map((item)=>(

                                         <tr>
                                         {state.customer_label_setting.hide_product ? <td>{state.customer_label_setting.trim_product_name_upto!=undefined && state.customer_label_setting.trim_product_name_upto!=""?(item.item_name.substring(0,state.customer_label_setting.trim_product_name_upto)):item.item_name}</td>:""}
                                         {state.customer_label_setting.hide_qty ? <td>{item.item_quantity}</td> : ""}
                                         {state.customer_label_setting.hide_total_amount ? <td>&#8377;{item.item_value}</td> : ""}
                                         </tr>
                                         ))}
                                                 

                                         

                                      </tbody>
                                  </table>
                              </div>
                              {!state.customer_label_setting.hide_pickup_address && !state.customer_label_setting.hide_pickup_mobile_number?"":
                              <div class="mb-2">
                                  <h2>Pickup Address:</h2>
                                  {state.customer_label_setting.hide_pickup_address ? <h4>{sub.warehouse_id?sub.warehouse_id:sub.sender[0].name}</h4> : ""}
                                  {state.customer_label_setting.hide_pickup_address ? <h3>{sub.sender[0].address} {sub.sender[0].city}, {sub.sender[0].state}, {sub.sender[0].country} - {sub.sender[0].pincode}</h3> : ""}
                                  <h3>{state.customer_label_setting.hide_pickup_mobile_number ? `Mobile No: ${sub.sender[0].mobile} ,` : ""} </h3>
                              </div>
}
                              {!state.customer_label_setting.hide_rto_address && !state.customer_label_setting.hide_rto_mobile_number?"":
                              <div class="mb-2">
                                  <h2>Return Address:</h2>
                                  {state.customer_label_setting.hide_rto_address ? <h4>{sub.warehouse_id?sub.warehouse_id:sub.return[0].name}</h4> : ""}
                                  {state.customer_label_setting.hide_rto_address ? <h3>{sub.return[0].address} {sub.return[0].city}, {sub.return[0].state}, {sub.return[0].country} - {sub.return[0].pincode}</h3> : ""}
                                  <h3>{state.customer_label_setting.hide_rto_mobile_number ? `Mobile No: ${sub.return[0].mobile} ,`  : ""}  </h3>
                              </div>
}
                             {!state.customer_label_setting.show_support_email_mobile ?"":
                              <div class="mb-2">
                                  <h2>For any query please contact:</h2>
                                  <h3>{state.customer_label_setting.show_support_email_mobile ? `Mobile: ${state.customer_label_setting.support_mobile ? state.customer_label_setting.support_mobile : ""}, Email: ${state.customer_label_setting.support_email ? state.customer_label_setting.support_email : ""}` : ""}</h3>
                              </div>}

                              <hr />
                              {/* <small>This is computer generated document, hence does not required signature.</small>
                              <div><strong>Note:</strong> <small>All disputes are subject to Delhi jurisdiction. Goods once sold will only be taken back or exchanged as per the store's exchange/return policy</small></div> */}
                               <div className='custom-row'>
  <div>
    <small>Powered By Shipdartexpress</small>
  </div>
  <div>
    <img src='../../../img/labels_icon_kw.png' width="70px" alt="Logo" />
  </div>
</div>



                          </div>
                      </div>

                  </section>
               </div>
              
           
               )}
             
             </div>  }


                {/* : ""}  */}

              </section>

            </div >


          </>
        )) : <div style={{ display: 'flex', justifyContent: 'center', marginLeft: "80px", alignItems: 'center', marginTop: "-200px", height: '100vh' }}>
          <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
        </div>}

    </div >
  )
}

export default Print_label