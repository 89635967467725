import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNetworkState } from 'react-use';
import Modal from 'react-bootstrap/Modal';
const Create_order_nav = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const { value } = props;
    const [activeTab, setActiveTab] = React.useState(1);
    const[modals,setModals] = React.useState({show: false})
    const handleChangeTab = (val) => {
        if (val === 2) {
          navigate('/customer/order_import');
        } else if (val === 0) {
          setActiveTab(1);
          navigate('/customer/create_order_1?value=0');
        } else {
          setActiveTab(val);
          navigate({
            pathname: '/customer/create_order_1',
            search: `value=${val}`,
          });
        }
      };
  
    // Use state to track the active tab
    
  
    React.useEffect(() => {
        // Determine the active tab based on the value prop or the current route location
        if (value !== undefined) {
          setActiveTab(value);
        } else if (location.pathname.includes('/customer/order_import')) {
          setActiveTab(2);
        }
        else {
            setActiveTab(1); // Set the default tab to 1 (Domestic Order)
          }
      }, [value, location.pathname]);
    
      const handleInternational = () => {
        if (activeTab !== 3) {
            setActiveTab(3);
            navigate('/customer/create_order_1?value=1');
          }
      };
  return (
    <div>
      <section class="mb-3">
          <div class="">
            <div class="row mb-3">
              <div class="col-12 col-md-3">
                <div class="d-flex">
                    <div class="">
                        <h3><small><a href="/customer/orders"><i class='bx bx-chevron-left icon'></i></a></small>Orders</h3>
                    </div>
                </div>
              </div>
              <div class="col-12 col-md">
                
              </div>
              <div class="col-12 col-md-4 text-end">
              <a onClick={(e)=>setModals({show:true})} class="btn btn-warning btn-sm "><i class="fa fa-lightbulb-o" aria-hidden="true"></i> Need help with creating order? </a>
              </div>
            </div> 
            <div>
                <ul class="nav nav-pills">
                    <li class="nav-item">
                      <a    className={activeTab === 1 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
            onClick={() => handleChangeTab(0)}>Domestic Order</a>
                    </li>
                    <li class="nav-item">
                      <a   className={activeTab === 3 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
            onClick={() => handleInternational()} >International Order</a>
                    </li>
                    <li class="nav-item">
                      <a  className={activeTab == 2 ? 'nav-link active' : 'nav-link'}
            style={{ cursor: 'pointer' }}
            onClick={() => handleChangeTab(2)}>Bulk Order</a>
                    </li>
                  </ul>
            </div> 
            <hr/>    
          </div>
        </section>
        <Modal show={modals.show} onHide={() => setModals({show:false})}>
  <Modal.Header closeButton>
    <Modal.Title>Create Order - Help Guide</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    
    <div class="">
  <div class="card">
   
    <div class="card-body">
      <p class="lead">The "Create Order" page allows you to create and manage new orders with ease. Follow these simple steps to complete your order creation:</p>

      <div class="mb-4">
        <h5>1. Buyer Information</h5>
        <p>Begin by entering the details of the buyer. This includes essential information like the buyer's name, address, contact number, and any other relevant details. Accurate buyer information ensures smooth order processing and delivery.</p>
      </div>

      <div class="mb-4">
        <h5>2. Warehouse Selection</h5>
        <p>Next, select the warehouse from which the order will be fulfilled. This step is crucial as it determines the inventory location and impacts shipping times.</p>
      </div>

      <div class="mb-4">
        <h5>3. Product Information</h5>
        <p>Provide details about the products being ordered. You can specify the product name, quantity, and other necessary attributes. Additionally, choose whether the order will be paid for on delivery (Cash on Delivery - COD) or prepaid.</p>
      </div>

      <div class="mb-4">
        <h5>4. Order Dimension and Dead Weight</h5>
        <p>Finally, input the order's dimensions (length, width, height) and dead weight (the actual weight of the package). These details are important for calculating shipping costs and ensuring accurate delivery.</p>
      </div>

      <div class="alert alert-info">
        <strong>Note:</strong> Once all steps are completed, you can review the order details and proceed to finalize the order.
      </div>
    </div>
  </div>
</div>
   
  </Modal.Body>
  <Modal.Footer>
    <button className="btn btn-secondary" onClick={()=>setModals({show:false})}>
      Close
    </button>

  </Modal.Footer>
</Modal>
    </div>
  )
}

export default Create_order_nav
